import React , { useEffect } from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import Testimonail from "../components/testimonial";

//AOS
import AOS from "aos";
import "aos/dist/aos.css";

export default function VirutalMirror() {

  useEffect(() => {

    AOS.init({
      
    });
    
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          OIC CRM | Grow your customer relationship with Powerful OIC CRM
        </title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta
          name="title"
          content="OIC CRM | Grow your customer relationship with Powerful OIC CRM"
        />
        <meta
          name="description"
          content="Allowing to see the whole customer database from every angle with data security. OIC CRM is the most needed to grow the customer relationship in the optical industry."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oicapps.com/" />
        <meta
          property="og:description"
          content="Allowing to see the whole customer database from every angle with data security. OIC CRM is the most needed to grow the customer relationship in the optical industry."
        />
      </Helmet>
      <Layout>
        <div
          className="bannerImage"
          style={{
            backgroundImage: `url("https://oicweb-media.s3.ap-south-1.amazonaws.com/crm-banner.jpg")`,
          }}
        >
          <img src="" alt="Customer relation management" className="altImage" />
          <div className="container">
            <div className="row">
              <div className="col-md-6 bannerTextPadder">
                <h1>
                  <span>GROW</span> YOUR <span>CLIENT RELATIONSHIPS</span> WITH
                  A POWERFUL <span>CRM APP</span>
                </h1>
                <a href="https://crm.oicapps.com/" target="_blank" className="buttonStyleWhite hvr-forward">
                  Start Free Demo
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="padding60" style={{ background: "#ECEDEF" }}>
          <div className="container" data-aos="fade-left" data-aos-duration="400">>
            <div className="row aligner">
              <div className="col-md-2"></div>
              <div className="col-md-8 text-center">
                <h2>
                  <span className="realistic-marker-highlight">CRM</span>
                </h2>
                <p style={{ textAlign: "center", lineHeight: "2" }}>
                  Retain customers with CRM that helps you recall customers for
                  next eye-exam, lens replenishment, new eye-frame arrival etc.
                  customized web & mobile SMS reminders. Customer satisfaction
                  index is also raised with complete order lifecycle management
                  starting with customer order booking to order ready messaging
                  and quick dispensing. Helps streamlining day to day
                  activities.
                </p>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </div>
        <div className="padding60">
          <div className="container" data-aos="fade-right" data-aos-duration="400">>
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>
                  <span className="realistic-marker-highlight">Features</span>
                </h2>
              </div>

              <div className="container IconBoxText">
                <div className="row featureglass">
                  <div className="featureglassesC"><img src={require("../img/hp/Features_Glass.png")} /></div>
                  <div className="col-md-6 text-center">
                    <div className="transitionRingsL" />  
                    <div className="transitionRingsModifiedL" />
                    <div className="featureMarginCorrection IconBox blinkTransition">
                      <img className="transitionCrmImages"></img>
                      <p className="transitionCrmText">
                        {/* <strong>Never Lose A Contact Again</strong> */}
                      </p>
                    </div>
                    <br></br>
                    <p className="featureIconDesc featureMarginTextCorrection">
                      Store, manage and label your customer contacts in one
                      centralized secured hub with OIC.
                    </p>
                  </div>
                  <div className="col-md-6 text-center">
                    <div className="transitionRingsR" />
                    <div className="transitionRingsModifiedR" />
                    <div className="IconBox blinkTransition">
                      <img className="transitionCrmImages transitionDirection"></img>
                      <p className="transitionCrmText transitionDirection">
                        {/* <strong>Know Your Clients Inside & Out</strong> */}
                      </p>
                    </div>
                    <br></br>
                    <p className="featureIconDesc">
                      Get a birds-eye view of your clients’ history ₋
                      appointments, payments, invoices, documents and
                      conversations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <div className="padding60 blueBackground">
          <section className="container-fluid " data-aos="fade-left" data-aos-duration="400">>
            <div className="row aligner">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <h2 className="realistic-marker-highlight">Campaigns</h2>
                <p>
                  Engage & upsell clients with interactive widgets, promotions,
                  and coupons. Personalized touch by greeting the customer on
                  their special days.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Try Now
                </a>
                <br></br> <br></br>
                <br></br> <br></br>
              </div>
              <div className="col-md-6 noPadding">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/crm-01.jpg"
                  className="img-responsive" alt="Sales Image with offers"
                ></img>
              </div>
            </div>
          </section>
        </div>
        <div className="padding60">
          <section className="container-fluid noPadding" data-aos="fade-right" data-aos-duration="400">>
            <div className="row aligner">
              <div className="col-md-7 DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/crm-02.jpg"
                  className="img-responsive" alt="Happy faces looking into a laptop"
                ></img>
              </div>
              <div className="col-md-4 paddingLeft">
                <h2 className="realistic-marker-highlight">
                  Customer Relationships As A Business Priority
                </h2>
                <p>
                  You can have the most amazing product to offer, but if you
                  don’t treat your customers right, you won’t sell a thing!
                  helps you maintain great relationships with customers, by
                  treating each and every one individually, not forgetting about
                  them, and being there when they need you.
                </p>
                <br></br> <br></br>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5 mobile-only">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/crm-02.jpg"
                  className="img-responsive" alt="Happy faces looking into a laptop"
                ></img>
              </div>
            </div>
          </section>
        </div>
        <div className="padding60 blueBackground">
          <section className="container-fluid " data-aos="fade-left" data-aos-duration="400">>
            <div className="row aligner">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <h2 className="realistic-marker-highlight">
                  Better Customer Retention
                </h2>
                <p>
                  Apart from being a great asset in finding and nurturing your
                  potential and new customers, CRM is also a great tool for
                  keeping your existing customers happy. A CRM comes up with a
                  handful of “customer retention”.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Try Now
                </a>
                <br></br> <br></br>
                <br></br> <br></br>
              </div>
              <div className="col-md-6 noPadding">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/crm-03.jpg"
                  className="img-responsive" alt="Customer retention image"
                ></img>
              </div>
            </div>
          </section>
        </div>
        <div className="padding60">
          <section className="container-fluid noPadding" data-aos="fade-right" data-aos-duration="400">>
            <div className="row aligner">
              <div className="col-md-7 DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/crm-04.jpg"
                  className="img-responsive" alt="CRM a super powerful communication tool"
                ></img>
              </div>
              <div className="col-md-4 paddingLeft">
                <h2 className="realistic-marker-highlight">
                  Better Knowledge Of Customers
                </h2>
                <p>
                  As simple as it may sound, but the mere fact that you have all
                  information stored on each contact in one place makes CRM a
                  super powerful communication tool.
                </p>
                <br></br> <br></br>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5 mobile-only">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/crm-04.jpg"
                  className="img-responsive" alt="CRM a super powerful communication tool"
                ></img>
              </div>
            </div>
          </section>
        </div>
        <Testimonail />
      </Layout>
    </div>
  );
}
